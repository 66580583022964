import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [0,2,5,7,9];

export const dictionary = {
		"/(dynamic)": [11,[2],[3]],
		"/(static)/(auth)/auth/cadastro": [25,[5,6,7]],
		"/(static)/(auth)/auth/forgot_password": [26,[5,6,7]],
		"/(static)/(auth)/auth/login": [27,[5,6,7]],
		"/(static)/(auth)/auth/login/temporario": [28,[5,6,7]],
		"/(static)/(auth)/auth/new_password": [~29,[5,6,7]],
		"/(static)/(auth)/auth/validate-email": [30,[5,6,7]],
		"/(dynamic)/busca": [13,[2],[3]],
		"/(static)/carrinho": [~34,[5,8]],
		"/(static)/carrinho/(checkout)/cadastro": [35,[5,8,9]],
		"/(static)/carrinho/(checkout)/checkout": [~36,[5,8,9]],
		"/(static)/carrinho/(checkout)/login": [~37,[5,8,9]],
		"/(static)/carrinho/(checkout)/login/temporario": [38,[5,8,9]],
		"/(static)/(auth)/check_email": [31,[5,6]],
		"/(static)/(auth)/criar_loja": [~32,[5,6]],
		"/(dynamic)/d/[...slug]": [~14,[2],[3]],
		"/(dynamic)/loja": [15,[2],[3]],
		"/(dynamic)/loja/[slug]": [~16,[2],[3]],
		"/(dynamic)/minha-conta/enderecos": [~17,[2,4],[3]],
		"/(dynamic)/minha-conta/enderecos/novo": [19,[2,4],[3]],
		"/(dynamic)/minha-conta/enderecos/[id]": [~18,[2,4],[3]],
		"/(dynamic)/minha-conta/pedidos": [~20,[2,4],[3]],
		"/(dynamic)/minha-conta/pedidos/[id]": [~21,[2,4],[3]],
		"/(dynamic)/minha-conta/perfil": [~22,[2,4],[3]],
		"/(dynamic)/minha-conta/vouchers": [~23,[2,4],[3]],
		"/(static)/novo-carrinho": [~39,[5,10]],
		"/(static)/novo-carrinho/checkout": [~40,[5,10]],
		"/product_detail": [42],
		"/(dynamic)/p/[slug]": [~24,[2],[3]],
		"/(static)/(auth)/redirect_to_panel": [33,[5,6]],
		"/search_results": [43],
		"/(static)/sg/[group_slug]": [~41,[5]],
		"/(dynamic)/[institutional_slug]": [~12,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';